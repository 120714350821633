import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@assets/images/search_icon.png";
import Location from "@assets/images/location.png";
import Road from "@assets/images/road.png";
import Fuel from "@assets/images/fuel.png";
import Speed from "@assets/images/speed.png";
import Note from "@assets/images/note.png";
import Moon from "@assets/images/moon.png";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { RiArrowRightSFill } from "react-icons/ri";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrucks,
  selectTrucks,
  selectTrucksSearchText,
  setTrucksSearchText,
} from "@slices/trucksSlice";
import _ from "@lodash";
import { Icon } from "@iconify/react";
import { COLORS } from "@constants";

const GPS_SYSTEM = [
  {
    ref: "1",
    id: "13200004381",
    timestamp: "1696067465",
    lat: "1.4710999999999999",
    lng: "103.92775",
    speed: "40",
    direction: 182,
    ACC: "1",
    GPSStat: "1",
    GPSantena: "1",
    GPRS: "1",
    milage: "12626",
    inter: "5",
    oilres: "1569.37",
    oilpercent: "0",
    oillvl: "0",
    address:
      "569, Jalan Keluli, Kawasan Perindustrian Pasir Gudang, 81700 Pasir Gudang, Johor, Malaysia",
    markerPosition: {
      lat: 1.4710999999999999,
      lng: 103.92775,
    },
    region: "Pasir Gudang, Johor",
    branch: "Pasir Gudang",
    fakeIP: "0000AB51",
    vehiclename: "MTL019",
    Isenabled: "1",
    PlateNumber: "JUD 5077",
    IC: null,
    maxoilvalue: null,
    minoilvalue: null,
  },
  {
    ref: "2",
    id: "13200004382",
    timestamp: "1628968538",
    lat: "1.5119686",
    lng: "103.8515396",
    speed: "0",
    direction: 2,
    ACC: "0",
    GPSStat: "1",
    GPSantena: "1",
    GPRS: "1",
    milage: "0",
    inter: "5",
    oilres: "817.148",
    oilpercent: "0",
    oillvl: "0",
    address:
      "15, Jalan Bukit 27, Bandar Baru Seri Alam, 81750 Masai, Johor, Malaysia",
    markerPosition: {
      lat: 1.5119686,
      lng: 103.8515396,
    },
    region: "Masai, Johor",
    branch: "Pasir Gudang",
    fakeIP: null,
    vehiclename: null,
    Isenabled: null,
    PlateNumber: null,
    IC: null,
    maxoilvalue: null,
    minoilvalue: null,
  },
  {
    ref: "4",
    id: "13200006297",
    timestamp: "1696067466",
    lat: "1.4759166666666665",
    lng: "103.92321666666666",
    speed: "35",
    direction: 192,
    ACC: "1",
    GPSStat: "1",
    GPSantena: "1",
    GPRS: "1",
    milage: "8282",
    inter: "5",
    oilres: "0",
    oilpercent: "0",
    oillvl: "0",
    address:
      "56, Jalan Bandar, Kawasan Perindustrian Pasir Gudang, 81700 Pasir Gudang, Johor, Malaysia",
    markerPosition: {
      lat: 1.4759166666666665,
      lng: 103.92321666666666,
    },
    region: "Pasir Gudang, Johor",
    branch: "Pasir Gudang",
    fakeIP: "0000BE61",
    vehiclename: "MTL014",
    Isenabled: "1",
    PlateNumber: "BPY 5009",
    IC: null,
    maxoilvalue: null,
    minoilvalue: null,
  },
  {
    ref: "6",
    id: "13200006666",
    timestamp: "1683917543",
    lat: "1.47887",
    lng: "103.928",
    speed: "0",
    direction: 152,
    ACC: "1",
    GPSStat: "1",
    GPSantena: "1",
    GPRS: "1",
    milage: "2071",
    inter: "5",
    oilres: "1401.56",
    oilpercent: "0",
    oillvl: "0",
    address:
      "PLO 703, 3, Jalan Nikel 4, Kawasan Perindustrian Pasir Gudang, 81700 Pasir Gudang, Johor, Malaysia",
    markerPosition: {
      lat: 1.47887,
      lng: 103.928,
    },
    region: "Pasir Gudang, Johor",
    branch: "Cameron",
    fakeIP: "demo",
    vehiclename: "MTL013",
    Isenabled: "1",
    PlateNumber: "BPY 6666",
    IC: null,
    maxoilvalue: null,
    minoilvalue: null,
  },
];

const Sidebar = (props) => {
  const {
    setTrackDetails,
    setSelectedMarkers,
    selectedMarkers,
    activeTab,
    setActiveTab,
  } = props;

  // const trucks = useSelector(selectTrucks);
  const dispatch = useDispatch();
  const searchText = useSelector(selectTrucksSearchText);

  const data = props.data;
  const [expanded, setExpanded] = useState(0);
  const [loading, setIsLoading] = useState(0);
  const [datatracklist, setdatatracklist] = useState([]);
  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    // onloadsideloader();
    setdatatracklist(GPS_SYSTEM[0].all_trucks);
    // setData(GPS_SYSTEM);
  }, []);

  const handleCheckboxChange = (truckID, isChecked) => {
    if (isChecked) {
      setSelectedMarkers((prevMarkers) => [...prevMarkers, truckID]);
    } else {
      setSelectedMarkers((prevMarkers) =>
        prevMarkers.filter((id) => id !== truckID)
      );
    }
    setTrackDetails(false);
  };

  const groupByBranch = data.reduce((acc, curr) => {
    if (!acc[curr.branch]) {
      acc[curr.branch] = [];
    }
    acc[curr.branch].push(curr);
    return acc;
  }, {});

  const areAllTrucksInBranchSelected = (branchTrucks) => {
    return branchTrucks.every((truck) => selectedMarkers.includes(truck.id));
  };

  const handleAllTracksCheckboxChange = (branchTrucks, isChecked) => {
    if (isChecked) {
      const newSelectedMarkers = [
        ...selectedMarkers,
        ...branchTrucks.map((truck) => truck.id),
      ];
      setSelectedMarkers(_.uniq(newSelectedMarkers));
    } else {
      const newSelectedMarkers = selectedMarkers.filter(
        (id) => !branchTrucks.map((truck) => truck.id).includes(id)
      );
      setSelectedMarkers(newSelectedMarkers);
    }
  };

  return (
    <React.Fragment>
      <Grid container style={{ backgroundColor: "white" }}>
        <Grid item xs={12} md={12} >
          <Box className="sidebar_search df ac">
            <img src={SearchIcon} />
            <input
              className="sidebar_search_input"
              placeholder="Trucks ID, Plate No, Location..."
              value={searchText}
              onChange={(e) => dispatch(setTrucksSearchText(e))}
            />
          </Box>
        </Grid>
        {Object.entries(groupByBranch).map(
          ([branchName, branchData], index) => (
            <Accordion
              className="Accordion"
              // expanded={expanded === 1}
              // onChange={handleChange(1)}
              key={index + "-" + branchName}
              defaultExpanded={window.innerWidth > 900}
            >
              <AccordionSummary
                expandIcon={
                  <MdOutlineArrowDropDown
                    size={22}
                    className="pointer"
                    color="white"
                  />
                }
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                className="sidebar_location_dropdown"
              >
                <Typography className="sidebar_location_name">
                  <IoLocationSharp className="sidebar_location_icon" />
                  {branchName}
                  {/* Pasir Gudang */}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Box className="all_tracks_status df ac jsb">
                  <Typography className="sidebar_allTrack df ac">
                    <input
                      className="sidebar_box_icon"
                      type="checkbox"
                      checked={areAllTrucksInBranchSelected(branchData)}
                      onChange={(e) =>
                        handleAllTracksCheckboxChange(
                          branchData,
                          e.target.checked
                        )
                      }
                    />
                    All Trucks
                  </Typography>
                  <Typography className="tracks_Status">Status</Typography>
                </Box>
                {/* {console.log(branchData)} */}
                {branchData?.map((item2) => (
                  <div key={item2.id} className="border_radius">
                    <Grid container>
                      <Grid item xs={1} className="vertical-place">
                        <input
                          className="sidebar_box_icon"
                          type="checkbox"
                          checked={selectedMarkers.includes(item2.id)}
                          onChange={(e) =>
                            handleCheckboxChange(item2?.id, e.target.checked)
                          }
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Accordion
                          className="Accordion"
                          sx={{
                            border: "none !important",
                            boxShadow: "none !important",
                            height: "100% !important",
                            // MozBorderRadiusTopleft: "0px !important",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <MdOutlineArrowDropDown
                                size={22}
                                className="pointer"
                                color="black"
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="single_track_box"
                          >
                            <div
                              onClick={() => setTrackDetails(item2?.id)}
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                // alignItems: "right",
                              }}
                            >
                              <Grid
                                container
                                className="vertical-place"
                                spacing={1}
                              >
                                <Grid item xs={5}>
                                  <Typography className="single_track_text df ac">
                                    {item2?.vehiclename
                                      ? item2?.vehiclename
                                      : "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography
                                    className="trach_current_status"
                                    style={{
                                      color:
                                        item2?.ACC === "0"
                                          ? "red"
                                          : parseInt(item2?.speed) > 0
                                          ? "#128539"
                                          : "rgba(175, 140, 14, 0.84)",
                                    }}
                                  >
                                    {item2?.speed} km/h
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <span>
                                    {item2.ACC === "1" && item2.speed != "0" ? (
                                      <Icon
                                        icon="carbon:play-filled"
                                        color={COLORS.green}
                                        className="icon-style"
                                        width="70%"
                                        height="70%"
                                      />
                                    ) : item2.ACC === "1" &&
                                      item2.speed === "0" ? (
                                      <Icon
                                        icon="mingcute:moon-fill"
                                        color={COLORS.yellow}
                                        className="icon-style"
                                        width="70%"
                                        height="70%"
                                      />
                                    ) : (
                                      <Icon
                                        icon="carbon:stop-filled"
                                        color={COLORS.red}
                                        className="icon-style"
                                        width="70%"
                                        height="70%"
                                      />
                                    )}
                                  </span>
                                </Grid>
                              </Grid>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container>
                              <Grid
                                item
                                xs={4}
                                className="Chip df ac jc"
                                style={{ backgroundColor: "#475C68" }}
                                onClick={() => {
                                  setTrackDetails(item2?.id);
                                  setActiveTab(1);
                                }}
                              >
                                <img src={Location} className="icon" />
                                {"Location"}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className="Chip df ac jc"
                                style={{ backgroundColor: "#460606AB" }}
                                onClick={() => {
                                  setTrackDetails(item2?.id);
                                  setActiveTab(2);
                                }}
                              >
                                <img src={Road} className="icon" />
                                {"Route"}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className="Chip df ac jc"
                                style={{ backgroundColor: "#595634" }}
                                onClick={() => {
                                  setTrackDetails(item2?.id);
                                  setActiveTab(3);
                                }}
                              >
                                <img src={Fuel} className="icon" />
                                {"Fuel"}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className="Chip df ac jc"
                                style={{ backgroundColor: "#6D3260" }}
                                onClick={() => {
                                  setTrackDetails(item2?.id);
                                  setActiveTab(4);
                                }}
                              >
                                <img src={Speed} className="icon" />
                                {"Mileage"}
                              </Grid>
                              {/* <Grid
                                item
                                xs={4}
                                className="Chip df ac jc"
                                style={{ backgroundColor: "#4E4158" }}
                                onClick={() => {
                                  setTrackDetails(item2?.id);
                                  setActiveTab(5);
                                }}
                              >
                                <img src={Note} className="icon" />
                                {"Report"}
                              </Grid> */}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          )
        )}
      </Grid>
    </React.Fragment>
  );
};
export default Sidebar;
