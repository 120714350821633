import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DISABLEMAPS, MAP_REFRESH_RATE } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrucks,
  selectTrucks,
  selectTrucksSearchText,
} from "@slices/trucksSlice";
import {
  Header,
  Sidebar,
  MapInfoWindow,
  SplashScreen,
  Maps,
  LoadingSpinner,
  DialogModal,
} from "@components";
import _ from "lodash";

const MultiScreens = (props) => {
  const dispatch = useDispatch();
  const trucks = useSelector(selectTrucks);
  const [data, setData] = useState(trucks);
  const [loading, setLoading] = useState(true);
  const searchText = useSelector(selectTrucksSearchText);

  const MapHeight = "250";
  const Lat = "1.4790999999999999";
  const Lng = "103.92793333333333";
  const Zoom = "14";
  const totalMaps = [{}, {}, {}, {}, {}, {}];

  useEffect(() => {
    if (trucks.length === 0) {
      dispatch(getTrucks()).then((data) => {
        // setData(data.payload);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (data.length > 0) {
  //     setLoading(false);
  //   } else {
  //     setLoading(true);
  //   }
  // }, [data]);

  useEffect(() => {
    if (searchText.length !== 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setData(
        _.filter(trucks, (item) => {
          // const lowerCaseItemName = item.name.toLowerCase();
          // const lowerCaseBankName = item.bank_name.toLowerCase();
          // const lowerCaseCountryName = item.country_name.toLowerCase();
          // const lowerCaseStatus = item.status.toLowerCase();
          // const isMerchantNameMatch =
          //   lowerCaseItemName.includes(lowerCaseSearchText);
          // const isBankNameMatch =
          //   lowerCaseBankName.includes(lowerCaseSearchText);
          // const isCountMatch = item.report_count.includes(lowerCaseSearchText);
          // const isBankNoMatch = item.bankNo.includes(lowerCaseSearchText);
          // const isCountryNameMatch =
          //   lowerCaseCountryName.includes(lowerCaseSearchText);
          // const isStatusMatch = lowerCaseStatus.includes(lowerCaseSearchText);
          // let isActionMatch = false;
          // if (item.is_merchant === "1") {
          //   const action =
          //     item.status === "unsafe" || item.status === "unknown"
          //       ? "unverify"
          //       : item.status === "safe"
          //       ? "verified"
          //       : "";
          //   isActionMatch = action.includes(lowerCaseSearchText);
          // }
          // return (
          //   isMerchantNameMatch ||
          //   isBankNameMatch ||
          //   isStatusMatch ||
          //   isCountMatch ||
          //   isBankNoMatch ||
          //   isCountryNameMatch ||
          //   isActionMatch
          // );
        })
      );
      // setData(FuseUtils.filterArrayByString(merchants, searchText));
      // setPage(0);
    } else {
      setData(trucks);
    }
  }, [trucks, searchText]);

  const linkGenerator = (lat, lng) => {
    const link =
      "https://maps.google.com/maps?width=100%25&amp;height=" +
      MapHeight +
      "&amp;hl=en&amp;q=" +
      lat +
      ",%20" +
      lng +
      "+(My%20Business%20Name)&amp;t=&amp;z=" +
      Zoom +
      "&amp;ie=UTF8&amp;iwloc=B&amp;output=embed";

    return link;
  };

  useEffect(() => {
    setTimeout(() => {
      data.map((item, index) => {
        const element = document.getElementById(`MapsArea${index}`);
        if (element) {
          element.innerHTML =
            "<iframe width='100%' title='maps' height=" +
            MapHeight +
            " src=" +
            linkGenerator(item.lat, item.lng) +
            "> <a href='https://www.maps.ie/population/'>" +
            "Find Population on Map </a> </iframe>";
        }
      });
    }, 0);
  }, [data]);

  /*** refresh data and map */
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getTrucks());
    }, MAP_REFRESH_RATE);

    return () => clearInterval(interval);
  }, [dispatch]);

  // document.getElementById("Maps_area").innerHTML += "<div><iframe width='100%' height='250' src=" +
  //         srcUrl+
  //         "> <a href='https://www.maps.ie/population/'> Find Population on Map </a> </iframe></div>";

  return (
    <Grid container>
      {
        loading ? (
          <LoadingSpinner />
        ) : data.length > 4 ? (
          data.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              style={{ height: MapHeight, width: "100%", position: "relative" }}
              key={item.id}
            >
              {DISABLEMAPS ? (
                <span>maps</span>
              ) : (
                // <span>Google Map</span>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div id={`MapsArea${index}`} style={{ width: "100%" }}></div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "rgba(25, 126, 193, 0.8)",
                      color: "white",
                      height: "10%",
                      width: "18%",
                      textAlign: "center",
                      verticalAlign: "middle",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>{item.PlateNumber}</span>
                  </div>
                </div>
              )}
            </Grid>
          ))
        ) : (
          data.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              key={item.id}
              style={{ height: MapHeight, width: "100%", position: "relative" }}
            >
              {DISABLEMAPS ? (
                <span>maps</span>
              ) : (
                // <span>Google Map</span>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div id={`MapsArea${index}`} style={{ width: "100%" }}></div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "rgba(25, 126, 193, 0.8)",
                      color: "white",
                      height: "10%",
                      width: "18%",
                      textAlign: "center",
                      verticalAlign: "middle",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>{item.PlateNumber}</span>
                  </div>
                </div>
              )}
            </Grid>
          ))
        )

        // {/* <Grid item xs={12} sm={6} md={4} style={{ height: "250px" }}>
        //   {DISABLEMAPS ? (
        //     <span>maps</span>
        //   ) : (
        //     // <span>Google Map</span>
        //     <iframe
        //       title="map2"
        //       width="100%"
        //       height="250px"
        //       frameborder="0"
        //       marginheight="0"
        //       marginwidth="0"
        //       src={`https://maps.google.com/maps?width=100%25&amp;height=${MapHeight}&amp;hl=en&amp;q=${Lat},%20${Lng}+(My%20Business%20Name)&amp;t=&amp;z=${Zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}
        //     >
        //       <a href="https://www.maps.ie/distance-area-calculator.html">
        //         measure area map
        //       </a>
        //     </iframe>
        //   )}
        // </Grid>
        // <Grid item xs={12} sm={6} md={4} style={{ height: "250px" }}>
        //   {DISABLEMAPS ? (
        //     <span>maps</span>
        //   ) : (
        //     // <span>Google Map</span>
        //     <iframe
        //       title="map3"
        //       width="100%"
        //       height="250px"
        //       frameborder="0"
        //       marginheight="0"
        //       marginwidth="0"
        //       src={`https://maps.google.com/maps?width=100%25&amp;height=${MapHeight}&amp;hl=en&amp;q=${Lat},%20${Lng}+(My%20Business%20Name)&amp;t=&amp;z=${Zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}
        //     >
        //       <a href="https://www.maps.ie/distance-area-calculator.html">
        //         measure area map
        //       </a>
        //     </iframe>
        //   )}
        // </Grid>
        // <Grid item xs={12} sm={6} md={4} style={{ height: "250px" }}>
        //   {DISABLEMAPS ? (
        //     <span>maps</span>
        //   ) : (
        //     // <span>Google Map</span>
        //     <iframe
        //       title="map4"
        //       width="100%"
        //       height="250px"
        //       frameborder="0"
        //       marginheight="0"
        //       marginwidth="0"
        //       src={`https://maps.google.com/maps?width=100%25&amp;height=${MapHeight}&amp;hl=en&amp;q=${Lat},%20${Lng}+(My%20Business%20Name)&amp;t=&amp;z=${Zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}
        //     >
        //       <a href="https://www.maps.ie/distance-area-calculator.html">
        //         measure area map
        //       </a>
        //     </iframe>
        //   )}
        // </Grid>
        // <Grid item xs={12} sm={6} md={4} style={{ height: "250px" }}>
        //   {DISABLEMAPS ? (
        //     <span>maps</span>
        //   ) : (
        //     // <span>Google Map</span>
        //     <iframe
        //       title="map5"
        //       width="100%"
        //       height="250px"
        //       frameborder="0"
        //       marginheight="0"
        //       marginwidth="0"
        //       src={`https://maps.google.com/maps?width=100%25&amp;height=${MapHeight}&amp;hl=en&amp;q=${Lat},%20${Lng}+(My%20Business%20Name)&amp;t=&amp;z=${Zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}
        //     >
        //       <a href="https://www.maps.ie/distance-area-calculator.html">
        //         measure area map
        //       </a>
        //     </iframe>
        //   )}
        // </Grid>
        // <Grid item xs={12} sm={6} md={4} style={{ height: "250px" }}>
        //   {DISABLEMAPS ? (
        //     <span>maps</span>
        //   ) : (
        //     // <span>Google Map</span>
        //     <iframe
        //       title="map6"
        //       width="100%"
        //       height="250px"
        //       frameborder="0"
        //       marginheight="0"
        //       marginwidth="0"
        //       src={`https://maps.google.com/maps?width=100%25&amp;height=${MapHeight}&amp;hl=en&amp;q=${Lat},%20${Lng}+(My%20Business%20Name)&amp;t=&amp;z=${Zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`}
        //     >
        //       <a href="https://www.maps.ie/distance-area-calculator.html">
        //         measure area map
        //       </a>
        //     </iframe>
        //   )}
        // </Grid> */}
      }
    </Grid>
  );
};
export default MultiScreens;
