const timestampConvert = (timestamp, type) => {
  const date = new Date(timestamp * 1000);

  const formattedUTCDate = `
    ${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${date.getFullYear()} ${String(
    date.getHours() % 12 || 12
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")} ${date.getHours() >= 12 ? "PM" : "AM"}`;

  const formattedGMTDate = `
    ${String(date.getUTCDate()).padStart(2, "0")}-${String(
    date.getUTCMonth() + 1
  ).padStart(2, "0")}-${date.getUTCFullYear()} ${String(
    date.getUTCHours() % 12 || 12
  ).padStart(2, "0")}:${String(date.getUTCMinutes()).padStart(2, "0")}:${String(
    date.getUTCSeconds()
  ).padStart(2, "0")} ${date.getUTCHours() >= 12 ? "PM" : "AM"}`;

  if (type === "gmt") return formattedGMTDate
  else return formattedUTCDate;
};

export default timestampConvert;
