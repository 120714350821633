import axios from "axios";
import * as Models from "@slices/models";
import * as Config from "@slices/serverConfig";

const ModelsInterchange = (item, model) => {
  switch (model) {
    case "TruckModel":
      {
        return new Models.TruckModel(item);
      }
      break;
    case "TruckDateRangeModel":
      {
        return new Models.TruckDateRangeModel(item);
      }
      break;
    case "TruckRouteModel":
      {
        return new Models.TruckRouteModel(item);
      }
      break;
    case "TruckStatsModel":
      {
        return new Models.TruckStatsModel(item);
      }
      break;
    case "LatestContinuousIdlingTimeModel":
      {
        return new Models.LatestContinuousIdlingTimeModel(item);
      }
      break;
    default:
      break;
  }
};

const directServer = async (props) => {
  const link = Config.Server?.link + "mode=" + props.mode;
  const data = props.data ? props.data : null;
  const model = props.model ? props.model : null;
  const params = new URLSearchParams();
  let dataList = [];

  let axiosRequest = null;

  if (data === null) {
    axiosRequest = {
      method: "get",
      url: link,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  } else if (data !== null) {
    Object.keys(data).forEach((key) => {
      params.append(key, data[key]);
    });
    axiosRequest = {
      method: "post",
      url: link,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }

  if (axiosRequest === null) return null;

  const response = await axios(axiosRequest);
  // const data = await response.data;

  // return data === undefined ? null : data;
  const json = await response.data;

  if (json.success) {
    dataList = json.data.map((item) => {
      if (model === null) {
        return item;
      }
      else {
        return ModelsInterchange(item, model);
      }
    });
    return dataList;
  } else {
    return { success: json.success };
  }
};

export default directServer;
