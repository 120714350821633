const sendNotification = (title, options) => {
  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support system notifications");
    return;
  }

  // Check whether notification permissions have already been granted
  if (Notification.permission === "granted") {
    // If it's okay, create a notification
    new Notification(title, options);
  } 
  // Otherwise, ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(permission => {
      // If the user accepts, create a notification
      if (permission === "granted") {
        new Notification(title, options);
      }
    });
  }
  // At last, if the user has denied notifications, and you 
  // want to respect their decision, there's no need to bother them any more.
};

export default sendNotification;