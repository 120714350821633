import React from "react";
import { Grid, Box, Button } from "@mui/material";
import Calendar from "@assets/images/calendar.png";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { COLORS } from "@constants";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
// import "./style.css";
// import 'moment/locale/my';

const newTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: "white !important",
            height: "35px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
            paddingLeft: "10px !important",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: "100% !important",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            marginRight: "8px !important",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            padding: "30px 0 0 45px !important",
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          root: {
            marginLeft: "45px !important",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "Mui-selected": {
              backgroundColor: COLORS.blue,
            },
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            marginLeft: "30px !important",
            marginTop: "30px !important",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            marginBottom: "30px !important",
            marginRight: "10px !important",
          },
        },
      },
    },
  });

const FilterByDate = (props) => {
  const { startDate, setStartDate, endDate, setEndDate, handleDateChange } =
    props;
  const disabled = props.disabled ? props.disabled : false;
  const readOnly = props.readOnly ? props.readOnly : false;

  const handleSearchClick = () => {
    handleDateChange(startDate, endDate);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={4.5}>
        <Grid container spacing={0}>
          <Grid item xs={0} md={1.5}></Grid>
          <Grid item xs={12} md={10}>
            <ThemeProvider theme={newTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoItem label="From:">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                    // slotProps={{
                    //   textField: {
                    //     helperText: 'Start Date',
                    //   },
                    // }}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                </DemoItem>
              </LocalizationProvider>
            </ThemeProvider>
          </Grid>
          <Grid item xs={0} md={0.5}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4.5}>
        <Grid container spacing={0}>
          <Grid item xs={0} md={0.5}></Grid>
          <Grid item xs={12} md={10}>
            <ThemeProvider theme={newTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoItem label="Till:">
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                </DemoItem>
              </LocalizationProvider>
            </ThemeProvider>
          </Grid>
          <Grid item xs={0} md={1.5}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={9}>
            <Button
              className="filter_by_search_btn"
              onClick={handleSearchClick}
              disabled={disabled}
            >
              {disabled ? "Searching..." : "Search"}
            </Button>
          </Grid>
          <Grid item xs={0} md={3}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FilterByDate;
