import { BaseUtils } from "@utils";
import { SplashScreen } from "@components";
import { Navigate } from "react-router-dom";
import settingsConfig from "./settingsConfig";
import PagesConfig from "src/pages/pagesConfig";
import SignInConfig from "../account/sign-in/SignInConfig";
import SignOutConfig from "../account/sign-out/SignOutConfig";
import SignUpConfig from "../account/sign-up/SignUpConfig";
import ForgotPasswordConfig from "../account/forgot-password/ForgotPasswordConfig";

const routeConfigs = [...PagesConfig, SignInConfig, SignOutConfig, SignUpConfig, ForgotPasswordConfig];

const routes = [
  ...BaseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="location" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "loading",
    element: <SplashScreen />,
  },
  {
    path: "*",
    element: <Navigate to="pagenotfound" />,
  },
];

export default routes;
