import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getMonitorLog,
  selectMonitorLog,
  selectMonitorLogSearchText,
} from "@slices/monitorLogSlice";
import {
  LoadingSpinner,
  DataTable,
  DialogModal,
} from "@components";
import _ from "lodash";
import { directServer } from "@utils";

const MonitoringLogs = (props) => {
  const dispatch = useDispatch();
  const dataList = useSelector(selectMonitorLog);
  const [data, setData] = useState(dataList);
  const [loading, setLoading] = useState(true);
  const searchText = useSelector(selectMonitorLogSearchText);

  useEffect(() => {
    dispatch(getMonitorLog()).then((data) => {
      // setData(data.payload);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (searchText.length !== 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setData(
        _.filter(dataList, (item) => {
          // const lowerCaseItemName = item.name.toLowerCase();
          // const lowerCaseBankName = item.bank_name.toLowerCase();
          // const lowerCaseCountryName = item.country_name.toLowerCase();
          // const lowerCaseStatus = item.status.toLowerCase();
          // const isMerchantNameMatch =
          //   lowerCaseItemName.includes(lowerCaseSearchText);
          // const isBankNameMatch =
          //   lowerCaseBankName.includes(lowerCaseSearchText);
          // const isCountMatch = item.report_count.includes(lowerCaseSearchText);
          // const isBankNoMatch = item.bankNo.includes(lowerCaseSearchText);
          // const isCountryNameMatch =
          //   lowerCaseCountryName.includes(lowerCaseSearchText);
          // const isStatusMatch = lowerCaseStatus.includes(lowerCaseSearchText);
          // let isActionMatch = false;
          // if (item.is_merchant === "1") {
          //   const action =
          //     item.status === "unsafe" || item.status === "unknown"
          //       ? "unverify"
          //       : item.status === "safe"
          //       ? "verified"
          //       : "";
          //   isActionMatch = action.includes(lowerCaseSearchText);
          // }
          // return (
          //   isMerchantNameMatch ||
          //   isBankNameMatch ||
          //   isStatusMatch ||
          //   isCountMatch ||
          //   isBankNoMatch ||
          //   isCountryNameMatch ||
          //   isActionMatch
          // );
        })
      );
      // setData(FuseUtils.filterArrayByString(merchants, searchText));
      // setPage(0);
    } else {
      setData(dataList);
    }
  }, [dataList, searchText]);

  const IncludedFields = [
    "id",
    "level",
    "truckID",
    "type",
    "detail",
    "created",
  ];

  const ColsNeedTooltip = ["detail"];

  const WidthDictionary = { id: 70, level: 100, truckID: 150, type: 150, detail: 270, created: 150 };

  return (
    <Grid container>
      {
        loading ? (
          <LoadingSpinner />
        ) : data.length > 0 ? (
          <DataTable
            itemData={data}
            includedFields={IncludedFields}
            widthDictionary={WidthDictionary}
            reportType={"monitoringLogs"}
            colsNeedTooltip={ColsNeedTooltip}
          />
        ) : ("No data found")
      }
    </Grid>
  );
};
export default MonitoringLogs;
