const RotateIcon = (imageSrc, rotationDegrees) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      // Translate and rotate
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(rotationDegrees * Math.PI / 180);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);

      resolve(canvas.toDataURL());
    };
    image.onerror = reject;
    image.src = imageSrc;
  });
};

export default RotateIcon;