import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Container } from '../';
import { BiNote } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box } from '@mui/material';
import { AiTwotoneSetting, AiFillHome } from 'react-icons/ai';
import Trans_logo from '@assets/images/trans_logo.png';
import { FaSignOutAlt } from "react-icons/fa";
import './style.css';
//////////////////////////////
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GrMenu } from 'react-icons/gr';

const HEADER_LINKS = [
  {
    name: 'home',
    icon: AiFillHome,
    link: '/',
  },
  {
    name: 'report',
    icon: BiNote,
    link: '/report',
  },
  {
    name: 'settings',
    icon: AiTwotoneSetting,
    link: '/setting',
  },
  {
    name: 'Logout',
    icon: FaSignOutAlt,
    link: '/sign-out',
  },
];

const Header = ({ setTrackDetails }) => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMainPage = () => {
    handleNavigate('/');
    setTrackDetails(false);
  };
  useEffect(() => {
    const timeInterval = setInterval(() => {
      let date = new Date();
      let time = date.toLocaleTimeString(); 
      setCurrentTime(time);
    }, 1000);

    // Clear the interval when the component is unmounted.
    return () => clearInterval(timeInterval);
  }, []);

  return (
    <Grid className="df jc">
      <Grid container className="header_main_container">
        <Grid item xs={10} sm={8} className="df ac">
          <img
            src={Trans_logo}
            onClick={handleMainPage}
            style={{ cursor: 'pointer' }}
          />
          <Typography className="Mewahtrans_GPS_heading">
            Mewahtrans GPS
          </Typography>
          <Typography className="header_time">{currentTime}</Typography>
        </Grid>
        <Grid item xs={0} sm={4} className="df ac je onLargeScreen">
          {HEADER_LINKS?.map((item, index) => (
            <Typography
              className={
                window.location.pathname == item?.link
                  ? 'header_links df ac active'
                  : 'header_links df ac'
              }
              key={index}
              onClick={() => handleNavigate(item?.link)}
            >
              <item.icon className="header_links_icons" />
              {item?.name}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={2} sm={4} className="df ac je onSmallScreen">
          <GrMenu onClick={handleClick} className="onSmallScreen menu_icon" />
        </Grid>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {HEADER_LINKS?.map((item, index) => (
            <Typography
              className={
                window.location.pathname == item?.link
                  ? 'header_links header_links_smallScreen df ac active'
                  : 'header_links header_links_smallScreen df ac'
              }
              key={index}
              onClick={() => handleNavigate(item?.link)}
            >
              <MenuItem onClick={handleClose}>
                <item.icon className="header_links_icons" />
                {item?.name}
              </MenuItem>
            </Typography>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};
export default Header;
