import React, { useEffect, useState } from "react";
import JwtService from "../../../authentication/services/jwtService";
import { useNavigate } from "react-router-dom";
import { COLORS } from "@constants";
import "./styles.css";

function SignOutPage() {
  const navigate = useNavigate();
  const redirectCount = 3; // 3 sec
  const startTimer = new Date().toLocaleTimeString();
  const [timeInterval, setTimeInterval] = useState(redirectCount);

  useEffect(() => {
    setTimeout(() => {
      JwtService.logout();
    }, 1000);
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     navigate("/");
  //   }, redirectCount);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimeInterval((prevTimeInterval) => prevTimeInterval - 1);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    if (timeInterval === 0) {
      navigate("/sign-in");
    }
  }, [timeInterval, navigate]);

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: COLORS.blue,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={require("@assets/images/appicon.png")}
            style={{ width: 100, height: 100 }}
          />
          <h1>You have signed out</h1>
          <p style={{ color: COLORS.white }}>
            Redirecting in {timeInterval} seconds...
          </p>
          <h2
            style={{
              color: COLORS.lightBlue,
              fontStyle: "italic",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            className="go-to-sign-in"
            onClick={() => navigate("/sign-in")}
          >
            Go to Sign In
          </h2>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignOutPage;
