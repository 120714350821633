import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  MAX_SNACK,
  DEFAULT_OVER_SPEED_WARNING,
  DEFAULT_OVER_SPEED_LIMIT,
  DEFAULT_IDLE_LIMIT_WARNING,
  DEFAULT_ARRIVED_WARNING,
  MAP_REFRESH_RATE,
  DEFAULT_FUEL_THEFT_WARNING,
} from "@constants";
import { AppContext } from "./WarningContext";
import { SnackbarProvider } from "notistack";

export const AppProvider = ({ children }) => {
  const [overSpeedWarning, setOverSpeedWarning] = useState(() => {
    const storedValue = localStorage.getItem("overSpeedWarning");
    return storedValue !== null
      ? JSON.parse(storedValue)
      : DEFAULT_OVER_SPEED_WARNING;
  });
  const [overSpeedLimit, setOverSpeedLimit] = useState(() => {
    const storedValue = localStorage.getItem("overSpeedLimit");
    return storedValue !== null
      ? JSON.parse(storedValue)
      : DEFAULT_OVER_SPEED_LIMIT;
  });
  const [idleLimitWarning, setIdleLimitWarning] = useState(() => {
    const storedValue = localStorage.getItem("idleLimitWarning");
    return storedValue !== null
      ? JSON.parse(storedValue)
      : DEFAULT_IDLE_LIMIT_WARNING;
  });
  const [arrivedWarning, setArrivedWarning] = useState(() => {
    const storedValue = localStorage.getItem("arrivedWarning");
    return storedValue !== null
      ? JSON.parse(storedValue)
      : DEFAULT_ARRIVED_WARNING;
  });
  const [fuelTheftWarning, setFuelTheftWarning] = useState(() => {
    const storedValue = localStorage.getItem("fuelTheftWarning");
    return storedValue !== null
      ? JSON.parse(storedValue)
      : DEFAULT_FUEL_THEFT_WARNING;
  });
  const [overSpeedWarningSound, setOverSpeedWarningSound] = useState(() => {
    const storedValue = localStorage.getItem("overSpeedWarningSound");
    return storedValue !== null ? JSON.parse(storedValue) : null;
  });
  const [idleLimitWarningSound, setIdleLimitWarningSound] = useState(() => {
    const storedValue = localStorage.getItem("idleLimitWarningSound");
    return storedValue !== null ? JSON.parse(storedValue) : null;
  });
  const [fuelTheftWarningSound, setFuelTheftWarningSound] = useState(() => {
    const storedValue = localStorage.getItem("fuelTheftWarningSound");
    return storedValue !== null ? JSON.parse(storedValue) : null;
  });
  const [mapsRefreshTimer, setMapsRefreshTimer] = useState(() => {
    const storedValue = localStorage.getItem("mapsRefreshTimer");
    return storedValue !== null ? JSON.parse(storedValue) : MAP_REFRESH_RATE;
  });

  useEffect(() => {
    // console.log(overSpeedWarning);
    localStorage.setItem("overSpeedWarning", JSON.stringify(overSpeedWarning));
    // triggerSnack();
  }, [overSpeedWarning]);

  useEffect(() => {
    localStorage.setItem("overSpeedLimit", JSON.stringify(overSpeedLimit));
  }, [overSpeedLimit]);

  useEffect(() => {
    localStorage.setItem("idleLimitWarning", JSON.stringify(idleLimitWarning));
  }, [idleLimitWarning]);

  useEffect(() => {
    localStorage.setItem("arrivedWarning", JSON.stringify(arrivedWarning));
  }, [arrivedWarning]);
  
  useEffect(() => {
    localStorage.setItem("fuelTheftWarning", JSON.stringify(fuelTheftWarning));
  }, [fuelTheftWarning]);

  useEffect(() => {
    localStorage.setItem(
      "overSpeedWarningSound",
      JSON.stringify(overSpeedWarningSound)
    );
  }, [overSpeedWarningSound]);

  useEffect(() => {
    localStorage.setItem(
      "idleLimitWarningSound",
      JSON.stringify(idleLimitWarningSound)
    );
  }, [idleLimitWarningSound]);
  
  useEffect(() => {
    localStorage.setItem(
      "fuelTheftWarningSound",
      JSON.stringify(fuelTheftWarningSound)
    );
  }, [fuelTheftWarningSound]);

  useEffect(() => {
    localStorage.setItem("mapsRefreshTimer", JSON.stringify(mapsRefreshTimer));
  }, [mapsRefreshTimer]);

  return (
    <AppContext.Provider
      value={{
        overSpeedWarning,
        setOverSpeedWarning,
        overSpeedLimit,
        setOverSpeedLimit,
        idleLimitWarning,
        setIdleLimitWarning,
        arrivedWarning,
        setArrivedWarning,
        overSpeedWarningSound,
        setOverSpeedWarningSound,
        idleLimitWarningSound,
        setIdleLimitWarningSound,
        mapsRefreshTimer,
        setMapsRefreshTimer,
        fuelTheftWarning,
        setFuelTheftWarning,
        fuelTheftWarningSound,
        setFuelTheftWarningSound,
      }}
    >
      <SnackbarProvider maxSnack={MAX_SNACK}>{children}</SnackbarProvider>
    </AppContext.Provider>
  );
};
