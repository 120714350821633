export default class TruckStatsModel {
  constructor(item) {
    this.id = item?.ref;
    this.truck_id = item?.id;
    this.date = item?.date;
    this.hour = item?.hour;
    this.initial_milage = item?.initial_milage;
    this.last_milage = item?.last_milage;
    this.initial_oilres = item?.initial_oilres;
    this.last_oilres = item?.last_oilres;
    this.initial_oillvl = item?.initial_oillvl;
    this.last_oillvl = item?.last_oillvl;
    this.total_idling_seconds = item?.total_idling_seconds;
    this.total_oil_consumed = item?.total_oil_consumed;
    this.total_oil_consumed_lvl = item?.total_oil_consumed_lvl;
    this.total_oil_added = item?.total_oil_added;
    this.total_oil_added_lvl = item?.total_oil_added_lvl;
    this.vehiclename = item?.vehiclename;
    this.PlateNumber = item?.PlateNumber;
    this.DriverName = item?.DriverName;
    this.IC = item?.IC;
    this.fuel_capacity = item?.fuel_capacity;
    this.branch = item?.branch;
    this.maxoilvalue = item?.maxoilvalue;
    this.minoilvalue = item?.minoilvalue;
  }
}
