import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import {
  Header,
  Dropdown,
  DatePicker,
  LoadingSpinner,
  DialogModal,
  DataTable,
} from "@components";
import Calendar from "@assets/images/calendar.png";
import "./style.css";
import _, { set } from "lodash";
////////////////////////////////////
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrucks,
  selectTrucks,
  selectTrucksSearchText,
} from "@slices/trucksSlice";
import moment from "moment";
import { COLORS } from "@constants";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  getTruckStatRange,
  selectTruckStats,
} from "@slices/truckDateRangeSlice";
import {
  getTruckStats,
  selectTruckStatsReport,
} from "@slices/truckStatsSlice";
import {
  getTruckRouteHistory,
  selectTruckRoute,
} from "@slices/truckRouteHistorySlice";
import { sec2Time } from "@utils";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const dropDownOptions = [
  {
    title: "Driving",
    value: "Mileage Report",
    label: "Mileage Report",
  },
  {
    title: "Driving",
    value: "Idling Report",
    label: "Idling Report",
  },
  {
    title: "History",
    value: "Tracking Report",
    label: "Tracking Report",
  },
  {
    title: "Fuel",
    value: "Consumption Report",
    label: "Consumption Report",
  },
  {
    title: "Fuel",
    value: "Fueling Report",
    label: "Fueling Report",
  },
];

const getColor = (index) => {
  const colors = [
    "#fd7f6f",
    "#7eb0d5",
    "#b2e061",
    "#bd7ebe",
    "#ffb55a",
    "#ffee65",
    "#beb9db",
    "#fdcce5",
    "#8bd3c7",
  ];

  if (index < colors.length) {
    return colors[index];
  } else {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  }
};

const Reports = (props) => {
  const dispatch = useDispatch();
  const trucks = useSelector(selectTrucks);
  const [data, setData] = useState(trucks);
  const [loading, setLoading] = useState(true);
  const searchText = useSelector(selectTrucksSearchText);
  const [reportType, setReportType] = useState("");
  const [truckID, setTruckID] = useState([]);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [errorMessage, setErrorMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportDataMan, setReportDataMan] = useState({});
  const [graphData, setGraphData] = useState(null);
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphOptions, setGraphOptions] = useState({});
  const [reportTitle, setReportTitle] = useState(
    "Please search for the report..."
  );
  const [buttonTitle, setButtonTitle] = useState("Not Available");
  const [includedFields, setIncludedFields] = useState([]);
  const [excludedFields, setExcludedFields] = useState([]);
  const [extraFields, setExtraFields] = useState([]);
  const [widthDictionary, setWidthDictionary] = useState({});
  const [titleDictionary, setTitleDictionary] = useState({});
  const [checkboxSelection, setCheckboxSelection] = useState(false);

  const truckDropDownOptions = data.map((item) => {
    return {
      title: "",
      value: item.id,
      label: item.PlateNumber + " (" + item.vehiclename + ")",
    };
  });

  useEffect(() => {
    if (trucks.length === 0) {
      dispatch(getTrucks()).then((data) => {
        const allTruckIDs = Array.from(
          new Set(data.payload.map((item) => item.id))
        );
        setTruckID(allTruckIDs);
        setLoading(false);
      });
    } else {
      const allTruckIDs = Array.from(new Set(trucks.map((item) => item.id)));
      setTruckID(allTruckIDs);
      setLoading(false);
    }
  }, [dispatch, trucks]);

  useEffect(() => {
    if (searchText.length !== 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setData(
        _.filter(trucks, (item) => {
          // const lowerCaseItemName = item.name.toLowerCase();
          // const lowerCaseBankName = item.bank_name.toLowerCase();
          // const lowerCaseCountryName = item.country_name.toLowerCase();
          // const lowerCaseStatus = item.status.toLowerCase();
          // const isMerchantNameMatch =
          //   lowerCaseItemName.includes(lowerCaseSearchText);
          // const isBankNameMatch =
          //   lowerCaseBankName.includes(lowerCaseSearchText);
          // const isCountMatch = item.report_count.includes(lowerCaseSearchText);
          // const isBankNoMatch = item.bankNo.includes(lowerCaseSearchText);
          // const isCountryNameMatch =
          //   lowerCaseCountryName.includes(lowerCaseSearchText);
          // const isStatusMatch = lowerCaseStatus.includes(lowerCaseSearchText);
          // let isActionMatch = false;
          // if (item.is_merchant === "1") {
          //   const action =
          //     item.status === "unsafe" || item.status === "unknown"
          //       ? "unverify"
          //       : item.status === "safe"
          //       ? "verified"
          //       : "";
          //   isActionMatch = action.includes(lowerCaseSearchText);
          // }
          // return (
          //   isMerchantNameMatch ||
          //   isBankNameMatch ||
          //   isStatusMatch ||
          //   isCountMatch ||
          //   isBankNoMatch ||
          //   isCountryNameMatch ||
          //   isActionMatch
          // );
        })
      );
      // setData(FuseUtils.filterArrayByString(merchants, searchText));
      // setPage(0);
    } else {
      setData(trucks);
    }
  }, [trucks, searchText]);

  useEffect(() => {
    const allTruckIDs = Array.from(new Set(data.map((item) => item.id)));
    setTruckID(allTruckIDs);
  }, [trucks]);

  const handleModalStats = () => {
    setIsDialogOpen(false);
  };

  const handleSearchClick = () => {
    let messages = [];
    let error = false;
    setButtonTitle("Not Available");
    setIncludedFields([]);
    setExcludedFields([]);
    setExtraFields([]);
    setWidthDictionary({});
    setTitleDictionary({});
    setCheckboxSelection(false);
    setGraphData(null);
    setReportData([]);
    setGraphOptions({});

    if (reportType === "" || reportType === null) {
      messages.push("Please select Report Type");
      error = true;
    }

    if (truckID.length === 0) {
      messages.push("Please select Truck(s)");
      error = true;
    }

    if (fromDate === null) {
      messages.push("Please select From Date");
      error = true;
    }

    if (toDate === null) {
      messages.push("Please select To Date");
      error = true;
    }

    if (error) {
      const messageJSX = messages.map((msg, index) => (
        <React.Fragment key={index}>
          {msg}
          <br />
        </React.Fragment>
      ));
      setErrorMessage(messageJSX);
      setIsDialogOpen(true);
      return;
    } else if (!error) {
      setGraphLoading(true);

      switch (reportType) {
        case "Mileage Report":
          setButtonTitle("See Details");
          setReportTitle("Mileage Report");
          setIncludedFields([
            "id",
            "date",
            "hour",
            "vehiclename",
            "PlateNumber",
            "initial_milage",
            "last_milage",
          ]);
          setExtraFields([
            {
              field: "milage",
              value: (params) => {
                const milage =
                  parseFloat(params.row.last_milage) -
                  parseFloat(params.row.initial_milage);
                return milage.toFixed(1);
              },
            },
            // {
            //   field: "test",
            //   value: (params) => `${test}`,
            // },
          ]);
          setTitleDictionary({
            PlateNumber: "Plate No.",
            vehiclename: "Vehicle Code",
          });
          setWidthDictionary({ id: 80, hour: 80 });

          getStatsReportData();
          break;
        case "Idling Report":
          setButtonTitle("See Details");
          setReportTitle("Idling Report");
          setIncludedFields([
            "id",
            "date",
            "hour",
            "vehiclename",
            "PlateNumber",
            "total_idling_seconds",
          ]);
          setTitleDictionary({
            PlateNumber: "Plate No.",
            vehiclename: "Vehicle Code",
          });
          setWidthDictionary({ id: 80, hour: 80 });

          getStatsReportData();
          break;
        case "Tracking Report":
          setReportTitle("Tracking Report");
          setIncludedFields([
            "id",
            "time_interval",
            "vehiclename",
            "PlateNumber",
            "lat",
            "lng",
            "speed",
            "DriverName",
            "IC",
          ]);
          setTitleDictionary({
            time_interval: "Date Time",
            PlateNumber: "Plate No.",
            DriverName: "Driver Name",
            lat: "Latitude",
            lng: "Longitude",
            vehiclename: "Vehicle Code",
          });
          setWidthDictionary({ id: 80, hour: 80, speed: 80 });

          getStatsReportData();
          break;
        case "Consumption Report":
          setButtonTitle("See Details");
          setReportTitle("Consumption Report");
          setIncludedFields([
            "id",
            "date",
            "hour",
            "vehiclename",
            "PlateNumber",
            "initial_oilres",
            "last_oilres",
            "total_oil_consumed",
          ]);
          setTitleDictionary({
            PlateNumber: "Plate No.",
            initial_oilres: "Initial Oil Level",
            last_oilres: "Last Oil Level",
            vehiclename: "Vehicle Code",
          });
          setWidthDictionary({ id: 80, hour: 80 });

          getStatsReportData();
          break;
        case "Fueling Report":
          setButtonTitle("See Details");
          setReportTitle("Fueling Report");
          setIncludedFields([
            "id",
            "date",
            "hour",
            "vehiclename",
            "PlateNumber",
            "initial_oilres",
            "last_oilres",
            "total_oil_added",
          ]);
          setTitleDictionary({
            PlateNumber: "Plate No.",
            initial_oilres: "Initial Oil Level",
            last_oilres: "Last Oil Level",
            vehiclename: "Vehicle Code",
          });
          setWidthDictionary({ id: 80, hour: 80 });

          getStatsReportData();
          break;
        default:
          setReportTitle("Please choose a report...");
          break;
      }

      setGraphOptions({
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          title: {
            display: false,
            text: "Chart.js Bar Chart",
          },
          datalabels: {
            display: true,
            color: (ctx) => {
              const value = ctx.dataset.data[ctx.dataIndex];
              return value === 0 ? "black" : "white";
            },
            formatter: (value, context) => {
              if (reportType === "Idling Report") {
                return value != 0 ? sec2Time(value) : 0;
              }
              return value;
            },
          },
        },
      });
    }
  };

  const getStatsReportData = () => {
    const param = {
      from: moment(fromDate).unix(),
      to: moment(toDate).unix(),
      id: truckID,
    };

    if (reportType != "Tracking Report") {
      dispatch(getTruckStats(param)).then((res) => {
        setReportData(res?.payload);
        // console.log(reportDataMan)
        // manuplateData();
      });
    } else if (reportType === "Tracking Report") {
      param.period_case = "60";

      dispatch(getTruckRouteHistory(param)).then((res) => {
        setReportData(res?.payload);
      });
    }
  };

  useEffect(() => {
    if (
      reportData &&
      reportData.length > 0 &&
      reportType != "Tracking Report"
    ) {
      processData();
    } else if (
      reportData &&
      reportData.length > 0 &&
      reportType === "Tracking Report"
    ) {
      setGraphLoading(false);
    }
  }, [reportData]);

  const processData = () => {
    const result = {};
    // console.log(JSON.stringify(reportData));

    if (reportType != "Tracking Report") {
      reportData?.forEach((item) => {
        const {
          truck_id,
          date,
          id,
          hour,
          initial_milage,
          initial_oillvl,
          initial_oilres,
          last_milage,
          last_oillvl,
          last_oilres,
          vehiclename,
          PlateNumber,
          DriverName,
          IC,
          maxoilvalue,
          minoilvalue,
          ...otherData
        } = item;

        if (!result[truck_id]) {
          result[truck_id] = [];
        }

        const existingDateEntry = result[truck_id].find(
          (entry) => entry.date === date
        );

        if (existingDateEntry) {
          for (const key in otherData) {
            existingDateEntry[key] =
              (parseFloat(existingDateEntry[key]) || 0) +
              parseFloat(otherData[key]);
          }

          existingDateEntry.initial_milage = Math.min(
            existingDateEntry.initial_milage || Infinity,
            initial_milage
          );
          existingDateEntry.initial_oillvl = Math.min(
            existingDateEntry.initial_oillvl || Infinity,
            initial_oillvl
          );
          existingDateEntry.initial_oilres = Math.min(
            existingDateEntry.initial_oilres || Infinity,
            initial_oilres
          );

          existingDateEntry.last_milage = Math.max(
            existingDateEntry.last_milage || -Infinity,
            last_milage
          );
          existingDateEntry.last_oillvl = Math.max(
            existingDateEntry.last_oillvl || -Infinity,
            last_oillvl
          );
          existingDateEntry.last_oilres = Math.max(
            existingDateEntry.last_oilres || -Infinity,
            last_oilres
          );

          existingDateEntry.vehiclename = vehiclename;
          existingDateEntry.PlateNumber = PlateNumber;
          existingDateEntry.DriverName = DriverName;
          existingDateEntry.IC = IC;
          existingDateEntry.maxoilvalue = maxoilvalue;
          existingDateEntry.minoilvalue = minoilvalue;
        } else {
          result[truck_id].push({
            date,
            initial_milage,
            initial_oillvl,
            initial_oilres,
            last_milage,
            last_oillvl,
            last_oilres,
            vehiclename,
            PlateNumber,
            DriverName,
            IC,
            maxoilvalue,
            minoilvalue,
            ...otherData,
          });
        }
      });
    }

    setReportDataMan(result);
  };

  useEffect(() => {
    if (Object.keys(reportDataMan).length > 0) {
      // console.log("hi");
      manuplateData();
    }
  }, [reportDataMan]);

  const manuplateData = () => {
    const labels = [];
    let graphReportData = [];

    if (reportType !== "Tracking Report") {
      const firstId = Object.keys(reportDataMan)[0];
      const arrayForFirstId = reportDataMan[firstId];

      arrayForFirstId?.forEach((item) => {
        labels.push(`${item.date}`);
      });

      graphReportData = Object.entries(reportDataMan).map(
        ([id, data], index) => {
          if (reportType === "Mileage Report") {
            return {
              label:
                data[0].vehiclename + " - " + data[0].PlateNumber + " (km)",
              data: data.map(
                (item) =>
                  parseFloat(item.last_milage) - parseFloat(item.initial_milage)
              ),
              backgroundColor: getColor(index),
              align: "top",
            };
          } else if (reportType === "Idling Report") {
            return {
              label:
                data[0].vehiclename + " - " + data[0].PlateNumber + " (sec) ",
              data: data.map((item) => item.total_idling_seconds),
              backgroundColor: getColor(index),
              align: "top",
            };
          } else if (reportType === "Consumption Report") {
            return {
              label: data[0].vehiclename + " - " + data[0].PlateNumber + " (L)",
              data: data.map((item) => item.total_oil_consumed),
              backgroundColor: getColor(index),
              align: "top",
            };
          } else if (reportType === "Fueling Report") {
            return {
              label: data[0].vehiclename + " - " + data[0].PlateNumber + " (L)",
              data: data.map((item) => item.total_oil_added),
              backgroundColor: getColor(index),
              align: "top",
            };
          }
        }
      );
    }

    setGraphData({
      labels,
      datasets: graphReportData,
    });
    // console.log("test");
    setGraphLoading(false);
  };

  const handleDetailsButton = () => {
    if (buttonTitle === "See Details") {
      setButtonTitle("Hide Details");
    } else {
      setButtonTitle("See Details");
    }
  };

  return (
    <Grid container>
      {isDialogOpen && (
        <DialogModal
          // title="Route History"
          message={errorMessage}
          handleModalStats={handleModalStats}
          isDialogOpen={isDialogOpen}
          icon={{
            icon: "carbon:warning-alt-filled",
            color: COLORS.yellow,
          }}
          customHeight={"30%"}
        />
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Grid container>
          <Grid item xs={12} md={10}>
            <Grid container>
              <Grid item xs={12} md={6} sm={12}>
                <Grid container>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography className="report_type_heading">
                      Report Type:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    sm={12}
                    className="selector-layout"
                  >
                    <Dropdown
                      selections={dropDownOptions}
                      withTitle
                      placeholderTitle={"Select Report"}
                      value={reportType}
                      setValue={setReportType}
                      disabled={graphLoading}
                    />
                  </Grid>
                  <Grid item xs={0} md={2} sm={0}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <Grid container>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography className="report_type_heading">
                      Lorry Number:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    sm={12}
                    className="selector-layout"
                  >
                    <Dropdown
                      selections={truckDropDownOptions}
                      placeholderTitle={"Select Vehicle"}
                      value={truckID}
                      setValue={setTruckID}
                      multiSelection
                      disabled={graphLoading}
                    />
                  </Grid>
                  <Grid item xs={0} md={2} sm={0}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography className="report_type_heading">
                      Starting Date:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    sm={12}
                    className="selector-layout"
                  >
                    <DatePicker
                      selectedDate={fromDate}
                      setSelectedDate={setFromDate}
                      disabled={graphLoading}
                    />
                  </Grid>
                  <Grid item xs={0} md={2} sm={0}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography className="report_type_heading">
                      Ending Date:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    sm={12}
                    className="selector-layout"
                  >
                    <DatePicker
                      selectedDate={toDate}
                      setSelectedDate={setToDate}
                      disabled={graphLoading}
                    />
                  </Grid>
                  <Grid item xs={0} md={2} sm={0}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="report_search_btn"
              onClick={handleSearchClick}
              disabled={graphLoading}
            >
              {graphLoading ? ("Searching...") : ("Search")}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "black",
              height: "2px",
            }}
            className="divider-line"
          />
          <Grid container className="on_time_kpi_container">
            <Grid item xs={12} className="on_time_kpi_grid1 df jsb ac">
              <Typography>{reportTitle}</Typography>
              <Button
                className="see_details_btn"
                onClick={handleDetailsButton}
                disabled={
                  reportType === "Tracking Report" ||
                  reportType === "" ||
                  reportType === null || buttonTitle === "Not Available"
                }
              >
                {buttonTitle}
              </Button>
            </Grid>
            <Grid item xs={12} className="on_time_kpi_grid2">
              {graphLoading ? (
                <LoadingSpinner />
              ) : (graphData === null && reportType != "Tracking Report") ||
                (reportData.length === 0 && reportType === "Tracking Report") ? (
                <span></span>
              ) : !graphLoading &&
                graphData &&
                buttonTitle === "See Details" ? (
                <span>
                  {/* {console.log(graphData)} */}
                  <Bar
                    options={graphOptions}
                    data={graphData}
                    style={{ width: "100%" }}
                  />
                </span>
              ) : !graphLoading &&
                reportData.length > 0 &&
                (buttonTitle === "Hide Details" ||
                  reportType === "Tracking Report") ? (
                <span>
                  {/* {console.log(reportData)} */}
                  <DataTable
                    itemData={reportData}
                    includedFields={includedFields}
                    excludedFields={excludedFields}
                    extraFields={extraFields}
                    checkboxSelection={checkboxSelection}
                    titleDictionary={titleDictionary}
                    widthDictionary={widthDictionary}
                    reportType={reportType}
                  />
                </span>
              ) : (
                <span>Something went wrong</span>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default Reports;