import React from "react";
import { Grid } from "@mui/material";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow,
  DrawingManager,
} from "@react-google-maps/api";
import { DISABLEMAPS, COMPANY_MAP_LOC } from "@constants";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const DrawingTool = () => {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  const onPolygonComplete = (polygon) => {
    console.log("Polygon created:", polygon);
  };

  return (
    <Grid container className="rest-container-drawing">
      <Grid item xs={12} style={{height: '100%'}}>
        {DISABLEMAPS ? (
          <span>map</span>
        ) : (
          // <span>Google Map</span>
          <GoogleMap
            center={COMPANY_MAP_LOC}
            zoom={10}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            // libraries={['drawing']}
          >
            <DrawingManager
              onPolygonComplete={onPolygonComplete}
              drawingMode="polygon"
            />
            <Marker position={defaultProps?.center} />
          </GoogleMap>
        )}
      </Grid>
    </Grid>
  );
};
export default DrawingTool;
