import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import {
  Maps,
  LoadingSpinner,
  DialogModal,
  FilterByDate,
  VerticalGuage,
} from "@components";
import { COLORS, DISABLEMAPS } from "@constants";
// import TruckRight from "@assets/images/LORRY1.png";
// import TruckLeft from "@assets/images/LORRY2.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getTruckStatRange } from "@slices/truckDateRangeSlice";
import { getTruckRouteHistory } from "@slices/truckRouteHistorySlice";
import { sec2Time, isMobileDevice } from "@utils";
import ChartDataLabels from "chartjs-plugin-datalabels";
import GaugeChart from "react-gauge-chart";

//Charts Data
ChartJS.register(
  CategoryScale,
  PointElement,
  LinearScale,
  LineElement,
  BarElement,
  Tooltip,
  Title,
  Legend,
  ChartDataLabels
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: true,
      text: "Mileage(KM) by Time",
    },
    datalabels: {
      display: false,
      color: "white",
    },
  },
};
const options_line = {
  responsive: true,
  plugins: {
    legend: { display: false, position: "top" },
    title: {
      display: true,
      text: "Fuel Consumption by Time",
    },
    datalabels: {
      display: false,
      color: "white",
    },
  },
};

//Components
const CurrentLocation = (props) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { trackDetails, data, selectedTruck, onRefreshData } = props;

  let coordinates = {
    lat: 1.479,
    lng: 103.628,
  };
  return (
    <div className="rest-container-inner">
      {!DISABLEMAPS ? (
        <>
          <Maps
            data={[selectedTruck]}
            fromTruckDetails
            onRefreshData={onRefreshData}
            // onClickFunc={setTrackDetails}
            // setTrackDetails={setTrackDetails}
            markersWindowHover
            // markersOnClick
            customMarker={{
              // left_image: TruckLeft,
              // right_image: TruckRight,
            }}
          />
          <div className="meter-view" style={{width: isMobileDevice() ? 200 : '25%'}}>
            <Box sx={{ width: 50, height: 170 }}>
              <VerticalGuage
                value={
                  parseFloat(selectedTruck.oilpercent) >= 0 ?
                    parseFloat(selectedTruck.oilpercent) : 0
                }
              />
            </Box>
            <GaugeChart
              id={`${moment().unix()}`}
              // nrOfLevels={22}
              arcsLength={[
                0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                0.04, 0.04, 0.04, 0.04, 0.04,
              ]}
              colors={[
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#00FF00",
                "#FFFF00",
                "#FFFF00",
                "#FFFF00",
                "#FF0000",
                "#FF0000",
                "#FF0000",
                "#FF0000",
                "#FF0000",
                "#FF0000",
              ]}
              textColor={"#000000"}
              className="gauge-chart"
              marginInPercent={0.02}
              // cornerRadius={3}
              percent={parseFloat(selectedTruck.speed) / 125}
              formatTextValue={() => selectedTruck.speed + " KM/H"}
            // arcPadding={0.02}
            />
          </div>
        </>
      ) : (
        // <span>Google Map</span>
        <span>Map</span>
      )}
    </div>
  );
};
const RouteHistory = (props) => {
  const [directions, setDirections] = useState(null);
  const [position, setPosition] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const { trackDetails, data, dispatch } = props;
  const [truckRouteLoading, setTruckRouteLoading] = useState(true);
  const [truckRoute, setTruckRoute] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // console.log("currentData", currentData);
  // console.log(truckRouteLoading);
  useEffect(() => {
    setTruckRouteLoading(true);
    setStartDate(moment());
    setEndDate(moment());
    const param = {
      from: moment().unix(),
      to: moment().unix(),
      id: trackDetails,
    };

    dispatch(getTruckRouteHistory(param)).then((res) => {
      if (res?.payload?.success === false) {
        setTruckRoute("");
        setTruckRouteLoading(false);
      } else {
        setTruckRoute(res?.payload);
        setTruckRouteLoading(false);
      }
    });
  }, [dispatch, trackDetails]);

  const handleModalStats = () => {
    setIsDialogOpen(false);
  };

  const handleDateChange = (from, to) => {
    setStartDate(from);
    setEndDate(to);

    if (moment(to).diff(moment(from), "days") + 1 <= 45) {
      setTruckRouteLoading(true);
      const param = {
        from: moment(from).unix(),
        to: moment(to).unix(),
        id: trackDetails,
      };
      // console.log("haha");
      dispatch(getTruckRouteHistory(param)).then((res) => {
        setTruckRoute(res?.payload);
        setTruckRouteLoading(false);
      });
    } else {
      setIsDialogOpen(true);
    }
  };

  return (
    <React.Fragment>
      {isDialogOpen && (
        <DialogModal
          // title="Route History"
          message="Selected date range cannot more than 45 days."
          handleModalStats={handleModalStats}
          isDialogOpen={isDialogOpen}
          icon={{
            icon: "carbon:warning-alt-filled",
            color: COLORS.yellow,
          }}
        />
      )}
      <Grid container>
        <Grid item xs={12}>
          <FilterByDate
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleDateChange={handleDateChange}
            disabled={truckRouteLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="rest-container-after-date">
            {!DISABLEMAPS ? (
              !truckRouteLoading && truckRoute !== "" ? (
                <Maps
                  data={data}
                  polygonLine={truckRoute}
                  id={moment().unix()}
                ></Maps>
              ) : truckRouteLoading ? (
                <LoadingSpinner />
              ) : (
                truckRoute === "" && "No Data"
              )
            ) : (
              // <span>Google Map</span>
              <span>Map</span>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
const FuelConsumption = (props) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const { trackDetails, data, dispatch } = props;
  const labels = [];
  const milageData = [];
  const [truckStats, setTruckStats] = useState(null);
  const [dataBar, setDataBar] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const param = {
      from: moment().unix(),
      to: moment().unix(),
      id: trackDetails,
    };

    dispatch(getTruckStatRange(param)).then((res) => {
      setTruckStats(res?.payload);
      manuplateData(res?.payload);
      setLoading(false);
    });
  }, [dispatch, trackDetails]);

  const handleDateChange = (from, to) => {
    setLoading(true);
    setStartDate(from);
    setEndDate(to);

    const param = {
      from: moment(from).unix(),
      to: moment(to).unix(),
      id: trackDetails,
    };
    dispatch(getTruckStatRange(param)).then((res) => {
      setTruckStats(res?.payload);
      manuplateData(res?.payload);
      setLoading(false);
    });
  };

  const manuplateData = (data) => {
    // console.log(data);
    if (data?.success === false) return;
    data?.forEach((item) => {
      let period = "";
      if (item.caseUnit === "day") {
        period = "";
      } else if (item.caseUnit === "hour") {
        if (parseInt(item.period) < 12) {
          period = item.period === "0" ? "12 AM" : `${item.period} AM`;
        } else {
          period =
            item.period === "12" ? "12 PM" : `${parseInt(item.period) - 12} PM`;
        }
      }
      labels.push(`${item.date} ${period}`);
      milageData.push(
        parseFloat(item.total_oil_consumed_percent_per_period) *
        parseFloat(item.fuel_capacity) / 100
      );
    });

    setDataBar({
      labels,
      datasets: [
        {
          label: "Volume (L)",
          data: milageData,
          borderColor: COLORS.lightBlue,
          backgroundColor: COLORS.lightBlue,
        },
      ],
    });
    // console.log(dataBar);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <FilterByDate
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleDateChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="rest-container-after-date">
            {loading ? (
              <LoadingSpinner />
            ) : !loading && dataBar === null ? (
              <span>No Data</span>
            ) : (
              <Line
                style={{ width: "100%", backgroundColor: "white" }}
                options={options_line}
                data={dataBar}
                className="rest-container-after-date"
              />
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
const MileageRun = (props) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const { trackDetails, data, dispatch } = props;
  const labels = [];
  const milageData = [];
  const [truckStats, setTruckStats] = useState(null);
  const [dataBar, setDataBar] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const param = {
      from: moment().unix(),
      to: moment().unix(),
      id: trackDetails,
    };

    dispatch(getTruckStatRange(param)).then((res) => {
      setTruckStats(res?.payload);
      manuplateData(res?.payload);
      setLoading(false);
    });
  }, [dispatch, trackDetails]);

  const handleDateChange = (from, to) => {
    setLoading(true);
    setStartDate(from);
    setEndDate(to);

    const param = {
      from: moment(from).unix(),
      to: moment(to).unix(),
      id: trackDetails,
    };
    dispatch(getTruckStatRange(param)).then((res) => {
      setTruckStats(res?.payload);
      manuplateData(res?.payload);
      setLoading(false);
    });
  };

  const manuplateData = (data) => {
    if (data?.success === false) return;
    data?.forEach((item) => {
      let period = "";
      if (item.caseUnit === "day") {
        period = "";
      } else if (item.caseUnit === "hour") {
        if (parseInt(item.period) < 12) {
          period = item.period === "0" ? "12 AM" : `${item.period} AM`;
        } else {
          period =
            item.period === "12" ? "12 PM" : `${parseInt(item.period) - 12} PM`;
        }
      }
      labels.push(`${item.date} ${period}`);
      milageData.push(
        parseFloat(item.last_milage_per_period) -
        parseFloat(item.initial_milage_per_period)
      );
    });

    setDataBar({
      labels,
      datasets: [
        {
          label: "Milage(KM)",
          data: milageData,
          backgroundColor: COLORS.lightBlue,
          align: "top",
        },
      ],
    });
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <FilterByDate
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleDateChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="rest-container-after-date">
            {loading ? (
              <LoadingSpinner />
            ) : !loading && dataBar === null ? (
              <span>No Data</span>
            ) : (
              <Bar
                options={options}
                data={dataBar}
                style={{ width: "100%", backgroundColor: "white" }}
                className="rest-container-after-date"
              />
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
const ReportHistory = (props) => {
  const [startDate, setStartDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment());
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <FilterByDate
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              backgroundColor: "transparent",
            }}
            className="rest-container-after-date"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const TruckDetails = (props) => {
  const dispatch = useDispatch();
  const { trackDetails, data, setTrackDetails, activeTab, setActiveTab, onRefreshData } = props;
  const [selectedTruck, setSelectedTrucks] = useState(
    data.find((truck) => truck.id === trackDetails)
  );

  useEffect(() => {
    setSelectedTrucks(data.find((truck) => truck.id === trackDetails));
  }, [trackDetails, data]);
  // const [activeTab, setActiveTab] = useState(activeTabNo ? activeTabNo : 1);

  const handleMainPage = () => {
    handleNavigate("/");
    setTrackDetails(false);
  };

  //Fake Data
  const DRIVER = [
    {
      label: "driver name:",
      values: "Symail",
    },
    {
      label: "truck no:",
      values: "MTL004",
    },
    {
      label: "car plate no:",
      values: "JSU5007",
    },
  ];
  const FUEL = [
    {
      label: "Location Milage:",
      values: "100 km",
    },
    {
      label: "Today Start Mileage:",
      values: "200 km",
    },
    {
      label: "Oil Level:",
      values: "100%",
    },
    {
      label: "Today oil consumption:",
      values: "20%",
    },
  ];
  const tabs = [
    {
      text: "Current Location",
      _id: 1,
    },
    {
      text: "Route History",
      _id: 2,
    },
    {
      text: "Fuel Consumption",
      _id: 3,
    },
    {
      text: "Mileage Run",
      _id: 4,
    },
    // {
    //   text: "Report History",
    //   _id: 5,
    // },
  ];

  // ActiveTabContent cannot be use in here as it will
  // let the tab unmouted and remounted and make the view keep reloading
  const ActiveTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <CurrentLocation
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
          />
        );
      case 2:
        return (
          <RouteHistory
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
          />
        );
      case 3:
        return (
          <FuelConsumption
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
          />
        );
      case 4:
        return (
          <MileageRun
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
          />
        );
      case 5:
        return (
          <ReportHistory
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
          />
        );
      default:
        <CurrentLocation
          trackDetails={trackDetails}
          data={data}
          selectedTruck={selectedTruck}
        />;
    }
  };
  const handleNavigate = (link) => {
    navigate(link);
  };
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Grid container className="button-container">
              <Grid item md={4} xs={12}>
                <Box
                  onClick={handleMainPage}
                  style={{ cursor: "pointer" }}
                  className="sidebar_search_driverdetail df ac"
                >
                  <Typography className="MTL004_heading">
                    {"< " +
                      selectedTruck?.vehiclename +
                      " (" +
                      selectedTruck?.PlateNumber +
                      ")"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={8} xs={0}></Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12} className="cells_table">
            <Grid container>
              <Grid item md={5} xs={6} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    Drive Name
                  </Grid>
                  <Grid item xs={12}>
                    Truck No
                  </Grid>

                </Grid>
              </Grid>
              <Grid item md={0.4} xs={0.4} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    :
                  </Grid>
                  <Grid item xs={12}>
                    :
                  </Grid>

                </Grid>
              </Grid>
              <Grid item md={6.6} xs={5.6} className="table_cells_values">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {selectedTruck.Drivername ? selectedTruck.Drivername : "-"}
                  </Grid>
                  <Grid item xs={12}>
                    {selectedTruck.vehiclename
                      ? selectedTruck.vehiclename
                      : "-"}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12} className="cells_table">
            <Grid container>
              <Grid item md={5} xs={6} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    Plate No
                  </Grid>
                  <Grid item xs={12}>
                    Idling Duration
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={0.4} xs={0.4} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    :
                  </Grid>
                  <Grid item xs={12}>
                    :
                  </Grid>

                </Grid>
              </Grid>
              <Grid item md={6.6} xs={5.6} className="table_cells_values">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {selectedTruck.PlateNumber
                      ? selectedTruck.PlateNumber
                      : "-"}
                  </Grid>
                  <Grid item xs={12}>
                    {selectedTruck.total_idling_seconds
                      ? sec2Time(selectedTruck.total_idling_seconds)
                      : "-"}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12} className="cells_table">
            <Grid container>
              <Grid item md={5} xs={6} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    Cumulative Mileage
                  </Grid>
                  <Grid item xs={12}>
                    Today's Mileage
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={0.4} xs={0.4} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    :
                  </Grid>
                  <Grid item xs={12}>
                    :
                  </Grid>

                </Grid>
              </Grid>
              <Grid item md={6.6} xs={5.6} className="table_cells_values">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {selectedTruck.milage ? selectedTruck.milage + " KM" : "-"}
                  </Grid>
                  <Grid item xs={12}>
                    {selectedTruck.initial_milage
                      ? parseFloat(selectedTruck.milage) -
                      parseFloat(selectedTruck.initial_milage) +
                      " KM"
                      : "-"}
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12} className="cells_table">
            <Grid container>
              <Grid item md={5} xs={6} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    Oil Level
                  </Grid>
                  <Grid item xs={12}>
                    Oil Consumption
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={0.4} xs={0.4} className="table_cells_label">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    :
                  </Grid>
                  <Grid item xs={12}>
                    :
                  </Grid>

                </Grid>
              </Grid>
              <Grid item md={6.6} xs={5.6} className="table_cells_values">
                <Grid container spacing={2}>

                  <Grid item xs={12}>
                    {parseFloat(selectedTruck.oilpercent) >= 0
                      ? parseFloat(selectedTruck.oilpercent).toFixed(2) + " %"
                      : "-"}
                  </Grid>
                  <Grid item xs={12}>
                    {selectedTruck.total_oil_consumed_percent
                      ? (
                        parseFloat(selectedTruck.total_oil_consumed_percent) *
                        parseFloat(selectedTruck.fuel_capacity) / 100
                      ).toFixed(
                        4
                      ) + " L"
                      : "-"}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: "black", height: "2px" }} />
      <Grid item xs={12} className="tabs_container">
        <Grid container spacing={0}>
          {tabs?.map((x) => (
            <Grid item xs={12} md={12 / tabs.length} key={x?._id}>
              <Grid container>
                <Grid item xs={0} md={0.5}></Grid>
                <Grid
                  item
                  xs={12}
                  md={11}
                  className={activeTab === x?._id ? "tabs_active" : "tabs"}
                  onClick={() => setActiveTab(x?._id)}
                >
                  {x?.text}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={activeTab === 1 ? "rest-container" : "rest-container-date"}
      >
        {activeTab === 1 && (
          <CurrentLocation
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
            onRefreshData={onRefreshData}
          />
        )}
        {activeTab === 2 && (
          <RouteHistory
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
            dispatch={dispatch}
          />
        )}
        {activeTab === 3 && (
          <FuelConsumption
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
            dispatch={dispatch}
          />
        )}
        {activeTab === 4 && (
          <MileageRun
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
            dispatch={dispatch}
          />
        )}
        {activeTab === 5 && (
          <ReportHistory
            trackDetails={trackDetails}
            data={data}
            selectedTruck={selectedTruck}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default TruckDetails;
