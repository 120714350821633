export default class TruckModel {
  constructor(item) {
    this.ref = item?.Ref;
    this.id = item?.ID;
    this.timestamp = item?.timestamp;
    this.lat = item?.lat;
    this.lng = item?.lng;
    this.speed = item?.speed;
    this.direction = parseFloat(item?.direction);
    this.ACC = item?.ACC;
    this.GPSStat = item?.GPSStat;
    this.GPSantena = item?.GPSantena;
    this.GPRS = item?.GPRS;
    this.milage = item?.milage;
    this.inter = item?.inter;
    this.oilres = item?.oilres;
    this.oilpercent = item?.oilpercent;
    this.oillvl = item?.oillvl;
    this.address = item?.address;
    this.markerPosition = {
      lat: parseFloat(item?.lat),
      lng: parseFloat(item?.lng),
    }
    this.region = item?.region;
    this.branch = item?.branch;
    this.fakeIP = item?.fakeIP;
    this.DeviceID = item?.DeviceID;
    this.vehiclename = item?.vehiclename;
    this.Isenabled = item?.Isenabled;
    this.PlateNumber = item?.PlateNumber;
    this.Drivername = item?.Drivername;
    this.IC = item?.IC;
    this.fuel_capacity = item?.fuel_capacity;
    this.maxoilvalue = item?.maxoilvalue;
    this.minoilvalue = item?.minoilvalue;
    this.date = item?.date;
    this.initial_milage = item?.initial_milage;
    this.last_milage = item?.last_milage;
    this.initial_oilres = item?.initial_oilres;
    this.last_oilres = item?.last_oilres;
    this.initial_oillvl = item?.initial_oillvl;
    this.last_oillvl = item?.last_oillvl;
    this.initial_oilpercent = item?.initial_oilpercent;
    this.last_oilpercent = item?.last_oilpercent;
    this.total_idling_seconds = item?.total_idling_seconds;
    this.total_oil_consumed = item?.total_oil_consumed;
    this.total_oil_consumed_lvl = item?.total_oil_consumed_lvl;
    this.total_oil_consumed_percent = item?.total_oil_consumed_percent;
    this.total_oil_added = item?.total_oil_added;
    this.total_oil_added_lvl = item?.total_oil_added_lvl;
    this.total_oil_added_percent = item?.total_oil_added_percent;
  }
}
