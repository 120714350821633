import { configureStore } from "@reduxjs/toolkit";
import reducers from "@slices";
import logger from 'redux-logger';

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => 
  getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(process.env.NODE_ENV !== 'production' ? logger : []),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;