import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled(({ hideArrow, ...otherProps }) => (
  <MuiAccordionSummary
    expandIcon={
      !hideArrow && <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
    }
    {...otherProps}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions(props) {
  const { expanded, panelId, summaryChildren } = props;
  const groupCollapse = props.groupCollapse ? props.groupCollapse : false;
  const onChange = props.onChange ? props.onChange : null;
  const detailsChildren = props?.children
    ? props.children
    : props.detailsChildren;
  const hideArrow = props?.hideArrow ? props.hideArrow : false;
  //*** this code should be put at main component */
  //**  const [expanded, setExpanded] = useState("panel1");

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // }; */

  return (
    <Accordion
      // {...(groupCollapse
      //   ? {
      //       expanded: expanded === panelId,
      //       onChange: onChange,
      //     }
      //   : {})}
      expanded={expanded}
    >
      <AccordionSummary
        // aria-controls="panel1d-content"
        // id="panel1d-header"
        hideArrow={hideArrow}
      >
        {summaryChildren ? (
          summaryChildren
        ) : (
          <Typography>Collapsible Group Item #1</Typography>
        )}
      </AccordionSummary>
      {detailsChildren && (
        <AccordionDetails>{detailsChildren}</AccordionDetails>
      )}
    </Accordion>
  );
}
