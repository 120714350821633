import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { LoadingSpinner } from "@components";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ExcelExporter } from "@utils";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';
import "./styles.css";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    // borderRight: `1px solid ${
    //   theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    // }`,
  },
  "& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cellContent": {
    padding: "0 9px !important",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 5,
  },
  "& .MuiTablePagination-input": {
    width: "50px !important",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid #aaaaaa`,
    margin: "0px 5px !important",
    borderRadius: "5px !important",
  },
  "& .MuiTablePagination-input .MuiSvgIcon-root": {
    paddingLeft: "10px !important",
  },
  "& .MuiTablePagination-select": {
    textAlign: "left !important",
    textAlignLast: "left !important",
    paddingLeft: "8px !important",
  },
  "& .MuiTooltip-popper": {
    height: "25px !important",
  },
  ...customCheckbox(theme),
}));

const newTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: "3px 6px !important",
          },
        },
      },
    },
  });

//*** data will be followed by the arragnement of includedFields
// const excludedFields = ["maxoilvalue", "minoilvalue"];

// const widthDictionary = {id: 50};

// const titleDictionary = {id: "ID"};

export default function DataTable(props) {
  const {
    itemData,
    includedFields,
    excludedFields,
    extraFields,
    reportType = "",
    colsNeedTooltip,
  } = props;
  const [titleData, setTitleData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const widthDictionary = props?.widthDictionary || {};
  const titleDictionary = props?.titleDictionary || {};
  const checkboxSelection = props?.checkboxSelection || false;
  const defaultAddWidth = 110;

  const handleExport = () => {
    let fileName = "exported_table";
    if (reportType !== "") {
      fileName = reportType.toLowerCase().replace(/ /g, "_");
    }
    ExcelExporter(itemsData, fileName);
  };

  const createColumns = () => {
    const firstItemKeys = Object.keys(itemData[0]);
    let included = [];

    if (includedFields && excludedFields) {
      included = includedFields.filter((key) => !excludedFields.includes(key));
    } else if (excludedFields) {
      included = firstItemKeys.filter((key) => !excludedFields.includes(key));
    } else if (includedFields) {
      included = includedFields;
    } else {
      included = firstItemKeys;
    }

    return included.map((key) => {
      const isNumber =
        !isNaN(parseFloat(itemData[0][key])) && isFinite(itemData[0][key]);

      return {
        field: key === "id" || key === "Id" || key === "ID" ? "id" : key,
        headerName:
          key === "id" || key === "Id" || key === "ID"
            ? "ID"
            : titleDictionary[key]
            ? titleDictionary[key]
            : key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()), //Convert name with _ to readable name
        width: widthDictionary[key] || key.length + defaultAddWidth,
        ...(isNumber ? { type: "number" } : {}),
        ...(colsNeedTooltip && colsNeedTooltip.includes(key)? {
          renderCell: (params) => (
            <Tooltip title={params.value || ''} enterDelay={500} leaveDelay={200}>
              <span>{params.value}</span>
            </Tooltip>
          ),
        } : {})
      };
    });
  };

  const createRows = () => {
    if (includedFields && excludedFields) {
      return itemData.map((item, index) => {
        // const row = { id: index + 1 };
        const row = {};
        for (const key of includedFields) {
          if (!excludedFields.includes(key)) {
            row[key] = item[key];
          }
        }
        return row;
      });
    } else if (excludedFields) {
      return itemData.map((item, index) => {
        // const row = { id: index + 1 };
        const row = {};
        for (const key of Object.keys(item)) {
          if (!excludedFields.includes(key)) {
            row[key] = item[key];
          }
        }
        return row;
      });
    } else if (includedFields) {
      return itemData.map((item, index) => {
        // const row = { id: index + 1 };
        const row = {};
        for (const key of includedFields) {
          row[key] = item[key];
        }
        return row;
      });
    } else {
      return itemData.map((item, index) => {
        // const row = { id: index + 1 };
        const row = {};
        for (const key of Object.keys(item)) {
          row[key] = item[key];
        }
        return row;
      });
    }
  };

  const createTableData = () => {
    const columns = createColumns();
    const rows = createRows();

    if (extraFields && extraFields.length > 0) {
      extraFields.map((item) => {
        columns.push({
          field: item.field,
          headerName: item.field
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase()),
          sortable: false,
          width:
            widthDictionary[item.field] || item.field.length + defaultAddWidth,
          valueGetter: item.value,
        });
      });
    }
    return { columns, rows };
  };

  useEffect(() => {
    const { columns, rows } = createTableData();
    // console.log(columns);

    setTitleData(columns);
    setItemsData(rows);
  }, [itemData]);

  useEffect(() => {
    if (titleData.length > 0 && itemsData.length > 0) {
      setLoading(false);
    }
  }, [titleData, itemsData]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Button
              onClick={handleExport}
              size="small"
              variant="contained"
              className="export-button"
            >
              Export to Excel
            </Button>
          </div>
          <ThemeProvider theme={newTheme}>
            <StyledDataGrid
              rows={itemsData}
              columns={titleData}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 20, 50, 100]}
              checkboxSelection={checkboxSelection}
            />
          </ThemeProvider>
        </div>
      )}
    </div>
  );
}
