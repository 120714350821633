import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { OptionGroup as BaseOptionGroup } from "@mui/base/OptionGroup";
import { Popper as BasePopper } from "@mui/base/Popper";
import { styled } from "@mui/system";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { COLORS } from "@constants";
import { Grid } from "@mui/material";
import "./style.css";

const Select = (props) => {
  const { width, ...otherProps } = props;
  const slots = {
    root: Button,
    listbox: Listbox,
    popper: (popProps) => <Popper {...popProps} width={width} />,
    ...props.slots,
  };

  return <BaseSelect {...otherProps} slots={slots} />;
};

const MultiSelect = forwardRef((props, ref) => {
  const { width, ...otherProps } = props;
  const slots = {
    root: Button,
    listbox: Listbox,
    popper: (popProps) => <Popper {...popProps} width={width} />,
    ...props.slots,
  };

  return <BaseSelect multiple ref={ref} {...otherProps} slots={slots} />;
});

Select.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
};

const OptionGroup = React.forwardRef(function CustomOptionGroup(props, ref) {
  const slots = {
    root: GroupRoot,
    label: GroupHeader,
    list: GroupOptions,
    ...props.slots,
  };

  return <BaseOptionGroup {...props} ref={ref} slots={slots} />;
});

OptionGroup.propTypes = {
  /**
   * The components used for each slot inside the OptionGroup.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    label: PropTypes.elementType,
    list: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Button = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled("button", { shouldForwardProp: () => true })(
  ({ theme }) => `
  position: relative;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 1.05rem;
  box-sizing: border-box;
  min-width: 150px;
  // padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  box-shadow: 0px 4px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0, 0, 0, 0.25)"
  };

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  width: 100%;
  height: 35px;
  padding-left: 9px !important;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 1rem !important;
  box-sizing: border-box;
  padding: 15px 18px 15px 18px!important;
  margin: 2px 0 !important;
  min-width: 150px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  // height: 35px !important;
    // display: flex;
    // align-items: center;
  `
);

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const GroupRoot = styled("li")`
  list-style: none;
`;

const GroupHeader = styled("span")`
  display: block;
  padding: 10px 10px 5px 10px !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 0.05rem !important;
  color: ${grey[600]};
`;

const GroupOptions = styled("ul")`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding: 0 18px !important;
    height: 35px !important;
    display: flex;
    // width: 86%;
    align-items: center;
    // justify-content: center;
  }
`;

const Popper = styled(BasePopper)`
  z-index: 1;
  width: ${({ width }) => `${width}px` || "100%"};
`;

const Selector = (props) => {
  const selectRef = useRef(null);
  const { selections, placeholderTitle, value, setValue } = props;
  const multiselect = props.multiSelection || false;
  const withTitle = props.withTitle || false;
  const disabled = props.disabled || false;
  const readOnly = props.readOnly || false;
  const [selectWidth, setSelectWidth] = useState(0);

  const truncateLabel = (label, length) => {
    let sub = 43;
    if (length > 500) {sub = 50}
    else if (length > 400) {sub = 43}
    else if (length > 350) {sub=34}
    else if (length > 300) {sub=28}
    else if(length > 200) {sub = 18}
    else {sub=10}
    const labelString = label.map((item) => item.label).join(", ");
    if (labelString.length > sub) {
      return labelString.substring(0, sub) + "...";
    }
    return labelString;
  };  

  useLayoutEffect(() => {
    if (selectRef.current) {
      setSelectWidth(selectRef.current.offsetWidth);
    }
  }, [selectRef]);

  const groupBy = selections.reduce((acc, curr) => {
    if (!acc[curr.title]) {
      acc[curr.title] = [];
    }
    acc[curr.title].push(curr);
    return acc;
  }, {});

  return (
    <Grid container>
      <Grid item xs={12} ref={selectRef}>
        {multiselect ? (
          <MultiSelect
            width={selectWidth}
            placeholder={placeholderTitle}
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            disabled={disabled}
            readOnly={readOnly}
            renderValue={(selected) =>
              truncateLabel(selected, selectWidth)
            }
          >
            {withTitle
              ? Object.entries(groupBy).map(([title, data], index) => (
                  <OptionGroup label={title} key={index + "-" + title}>
                    {data.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </OptionGroup>
                ))
              : selections.map((item) => (
                  <Option value={item.value} key={item.value} id="no-title">
                    {item.label}
                  </Option>
                ))}
          </MultiSelect>
        ) : (
          // <span>MultiSelect</span>
          <Select
            width={selectWidth}
            placeholder={placeholderTitle}
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            disabled={disabled}
            readOnly={readOnly}
          >
            {withTitle
              ? Object.entries(groupBy).map(([title, data], index) => (
                  <OptionGroup label={title} key={index + "-" + title}>
                    {data.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </OptionGroup>
                ))
              : selections.map((item) => (
                  <Option value={item.value} key={item.value} id="no-title">
                    {item.label}
                  </Option>
                ))}
            {/* <OptionGroup label="Elves">
            <Option value="Galadriel">Galadriel</Option>
            <Option value="Legolas">Legolas</Option>
          </OptionGroup> */}
          </Select>
        )}
      </Grid>
    </Grid>
  );
};

export default Selector;
