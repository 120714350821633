import { memo } from 'react';
import Box from '@mui/material/Box';
import LOGO from "@assets/images/appicon.png";
import './style.css';
// import GOOGLE_MAP_API_KEY from '../../constants/API_KEY';

function SplashScreen() {
  return (
    <div id="fuse-splash-screen">
      <div className="logo">
        <img width="128" src={LOGO} alt="logo" />
      </div>
      <Box
        id="spinner"
        sx={{
          '& > div': {
            backgroundColor: 'palette.secondary.main',
          },
        }}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </Box>
    </div>
  );
}

export default memo(SplashScreen);
