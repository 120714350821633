import React, { createContext, useState, useContext } from 'react';

export const AppContext = createContext({
  overSpeedWarning: false,
  setOverSpeedWarning: () => {},
  overSpeedLimit: 0,
  setOverSpeedLimit: () => {},
  idleLimitWarning: false,
  setIdleLimitWarning: () => {},
  arrivedWarning: false,
  setArrivedWarning: () => {},
  overSpeedWarningSound: null,
  setOverSpeedWarningSound: () => {},
  idleLimitWarningSound: null,
  setIdleLimitWarningSound: () => {},
  mapsRefreshTimer: 0,
  setMapsRefreshTimer: () => {},
  fuelTheftWarning: true,
  setFuelTheftWarning: () => {},
  fuelTheftWarningSound: "sharp_tone",
  setFuelTheftWarningSound: () => {},
});

export const useWarning = () => useContext(AppContext);
