import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { COLORS } from "@constants";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const newTheme = (theme, height) =>
  createTheme({
    ...theme,
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
            paddingLeft: "10px !important",
            width: "50%",
            height: height,
            padding: "20px !important",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            height: "20%",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            height: "100%",
            color: COLORS.blue,
          },
        },
      },
    },
  });

const AlertDialog = (props) => {
  const [open, setOpen] = React.useState(true);
  const title = props?.title;
  const message = props?.message;
  const icon = props?.icon;
  const isDialogOpen = props?.isDialogOpen;
  const handleModalStats = props?.handleModalStats;
  const customHeight = props?.customHeight || "20%";

  return (
    <div>
      <ThemeProvider theme={(outerTheme) => newTheme(outerTheme, customHeight)}>
        <Dialog
          open={isDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleModalStats}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            {title ? (
              title
            ) : (
              <div className="icon-title">
                <Icon
                  icon={icon?.icon}
                  color={icon?.color}
                  // className="icon-style"
                  width="9%"
                  height="9%"
                />
              </div>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="message-container"
            >
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalStats}>Close</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default AlertDialog;
